@import '../../../styles/_variables';
@import 'error-ux-components/screen';

.ui-search-error {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 25em;

  &__screen {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;

    .andes-technical-error__description {
      color: $search-gray-700;
    }
  }
}
